export class TranslationStore {
    private _initializer: Promise<unknown>;
    private _sections: { [id: string]: { [id: string]: string; } ; } 
    private static _instance = new TranslationStore();
    static get instance() { return TranslationStore._instance; }

    private constructor() {
        this._initializer = this.doInitialize();
    }

    doInitialize = () => {
        return new Promise(r => {
            document.addEventListener("DOMContentLoaded", () => {
                setTimeout(() => {
                    const textsElement = document.querySelector("script[type='application/json+translations']");
                    if (!textsElement) {
                        console.warn("Unable to find translations");
                        r(undefined);
                        return;
                    }
                        
                    this._sections = JSON.parse(textsElement.textContent).sections;
                    r(undefined);
                }, 50);
            }, { once: true });            
        });
    }

    async section(name: string): Promise<{ [id: string]: string; }> {
        await this._initializer;        
        const section = this._sections[name];

        if (!section)
            throw new Error(`Section not found: ${name}`);

        return section;
    }

    static format(source: string, args: any[]) : string {
        return source.replace(/\{(\d+)\}/g, (_,m) => args[m--]);
    }
}