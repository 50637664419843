declare var productsDataString: any;

export class ProductStore  {
    private static _instance = new ProductStore();
    private _products: Product[];
    public static get instance() { return this._instance; }

    private constructor() {

    }

    private initializeProducts() { 
        if (!(window as any).productsDataString)
            return;

        const parsed = JSON.parse(productsDataString) as ProductData;
        this._products = [];

        // flatten tyres
        for (const season of parsed.items) {

            if (season.TyreSizes)
                for (const size of season.TyreSizes) {

                    if (!size.Tyres)
                        continue;

                    for(const tyre of size.Tyres) {

                        this.parseAndAdd(tyre);
                    }
                }

            if (season.Rims)
                for (const rim of season.Rims) {
                    this.parseAndAdd(rim);
                }   
        }   
    }

    private parseAndAdd(tyre: Tyre) {
        let internalVendorDeliveries: any[];
        
        try {
            let json = tyre.vendorDeliveries;
            json = json.replace(/\\/g,"");
            internalVendorDeliveries = JSON.parse(json);
        } catch (e) {
            console.log(`Error parsing vendor deliveries: ${tyre.vendorDeliveries}`, e);
            return;
        }
        

        const vendorDeliveries = internalVendorDeliveries.map(vd => ({
            stockAvailable: vd.stockAvailable,
            shippingDate: new Date(vd.shippingDate),
            arrivalDate: new Date(vd.arrivalDate),
            isDefaultVendor: vd.isDefaultVendor
        }));


        // Sort vendor deliveries with the soonest shipping date first
        vendorDeliveries.sort((a, b) => a.shippingDate.getTime() - b.shippingDate.getTime());

        this._products.push({
            itemId: tyre.ia,
            vendorDeliveries: vendorDeliveries
        });
    }

    public getVendorByQuantity(itemId: number, quantity: number) : VendorDelivery | undefined {
        if (!this._products || this._products.length === 0)
            this.initializeProducts();

        if (!this._products)
            return undefined;

        const product = this._products.find(p => p.itemId === itemId);

        if (!product)
            return undefined;

        let wantedQuantity = quantity;
        
        let vendors = product.vendorDeliveries;
        if (vendors.some(m => m.isDefaultVendor))
            vendors = vendors.filter(m => m.isDefaultVendor);

        for (const vendorDelivery of vendors) {
            if (vendorDelivery.stockAvailable >= wantedQuantity) {
                return vendorDelivery;
            } else {
                wantedQuantity -= vendorDelivery.stockAvailable;
            }
        }

        return undefined;
    }

    // public init() {
    //     if (!this._products)
    //         this.initializeProducts();
    // }        
}

interface Tyre {
    ia: number;
    vendorDeliveries: string;
}


interface Rim {
    ia: number;
    vendorDeliveries: string;
}

interface TyreSize {
    Tyres: Tyre[];
}

interface TyreSeason {
    TyreSizes: TyreSize[];
    Rims: Rim[];
}

interface ProductData {
    items: TyreSeason[];
}

interface VendorDelivery {
    isDefaultVendor: boolean;
    stockAvailable: number;
    shippingDate: Date;
    arrivalDate: Date;
}

export interface Product {
    itemId: number;
    vendorDeliveries: VendorDelivery[];
}